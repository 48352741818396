import { useEffect, useState } from "react";
import { headers } from "../../../../core/constants";
import CovidForm from "./CovidForm";
import ShareholderDetailsForm from "./ShareholderDetailsForm";
import AdditionalQuestionsForm from "./AdditionalQuestionsForm";
import { fetchLeadShareholders } from "../../../../core/services";
import FormDocumentsPopup from "./FormDocumentsPopup";
import RedirectForm from "./RedirectForm";
import FormPopup from "../../../../components/CustomerComponents/FormPopup";
import FormPopupLayout from "../../../../components/CustomerComponents/FormPopupLayout";
import { Button, Form, Input, Progress, Space } from "antd";

const monthsBetween = (date1, date2) => {
  // Extract year and month from both dates
  let year1 = date1.getFullYear();
  let month1 = date1.getMonth();
  let year2 = date2.getFullYear();
  let month2 = date2.getMonth();

  // Calculate the difference in months
  let months = (year2 - year1) * 12 + (month2 - month1);

  return months;
};

const getMonthsBetweenCovid = () => {
  let today = new Date();
  let startDate = new Date(2021, 0, 1);
  let numberOfMonths = monthsBetween(startDate, today);
  return numberOfMonths;
};

export const FinanceOptionsFormsManager = ({
  isMobile,
  leadId,
  companyId,
  offerToSubmit,
  setOfferToSubmit,
  businessAgeNumericApprox,
  vendor,
  applicationData
}) => {
  const [covidFormVisible, setCovidFormVisible] = useState(false);
  const [shareHolderFormVisible, setShareHolderFormVisible] = useState(false);
  const [additionalQuestionsForm, setAdditionalQuestionsForm] = useState(false);
  const [redirectFormVisible, setRedirectFormVisible] = useState(false);
  const [popup, setPopup] = useState(false);
  const [pendingAcceptOffer, setPendingAcceptOffer] = useState(null);
  const [leadShareholdingInfo, setLeadShareholdingInfo] = useState(null);
  const skipShareholderDetails =
    leadShareholdingInfo?.applicantShareholder?.shareholding > 50;
  const [isLoading, setIsLoading] = useState(true);
  const [documentsRequired, setDocumentsRequired] = useState([]);
  const [redirectState, setRedirectState] = useState('');

  const skipCovidForm = getMonthsBetweenCovid() > businessAgeNumericApprox;
  const [isCapitalTapModalOpen, setIsCapitalTapModalOpen] = useState(false);
  const [isTriverModalOpen, setIsTriverModalOpen] = useState(false);
  const [offerAmount, setofferAmount] = useState("0")
  const [otpPopup, showOtpPopup] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [otpState, setOtpState] = useState("");
  const makeAcceptOfferRequest = async (offerId) => {
    const res = await fetch(
      `/api/offers/accept?offerId=${offerId}&leadId=${leadId}`,
      {
        method: "POST",
        headers,
        credentials: "include",
      }
    );

    // if (!acceptedOffers.includes(offerId)) {
    //   setAcceptedOffers([...acceptedOffers, offerId]);
    // }

    const acceptInfo = await res.json();
    return acceptInfo;
  };

  useEffect(() => {
    if (offerToSubmit && vendor !== 'fundingCircle') {
      setDocumentsRequired(offerToSubmit?.documents_required);
      handleSubmitOffer();
    }

  }, [offerToSubmit])

  const MODALS = {
    REDIRECT: setRedirectFormVisible,
    COVID: setCovidFormVisible,
    ADDITIONAL: setAdditionalQuestionsForm,
    SHAREHOLDER: setShareHolderFormVisible,
  };

  const handleCloseModal = (modal, clear) => {
    const closeFunc = MODALS[modal];
    if (typeof closeFunc === "function") {
      closeFunc(false);
    }
    // setFormKey(formKey + 1);
    document.body.classList.remove("modalOpen");

    if (clear) {
      setOfferToSubmit(null);
    }
  };

  const handleOpenModal = (modal) => {
    const openFunc = MODALS[modal];
    if (typeof openFunc === "function") {
      Object.keys(MODALS).forEach((key) => {
        if (key !== modal && typeof MODALS[key] === "function") {
          MODALS[key](false);
        }
      });

      openFunc(true);
    }
    document.body.classList.add("modalOpen");
  };

  const handleSubmitOffer = () => {
    const offer = offerToSubmit;
    const offerId = offer.offerId;

    setPendingAcceptOffer(offerId);

    let windowRef;
    if (vendor !== 'triver' && offer.buttonType === "redirect") {
      windowRef = window.open();
    }

    makeAcceptOfferRequest(offerId)
      .then((acceptInfo) => {
        if (acceptInfo.data.buttonType === "redirect") {
          windowRef.location = acceptInfo.data.redirectUrl;
        } else if (acceptInfo.data.buttonType === "popup") {
          if (offerToSubmit && process.env.REACT_APP_ENABLE_FC_POPUP === 'true' && vendor === 'fundingCircle') {
            setRedirectState(acceptInfo.data.content);
          } else if (offerToSubmit && process.env.REACT_APP_ENABLE_TRIVER_POPUP === 'true' && vendor === 'triver') {
            setIsTriverModalOpen(true);
          } else {
            setPopup(true);
          }
        } else if (vendor === 'capitalOnTap') {
          setofferAmount(offerToSubmit.amount)
          setIsCapitalTapModalOpen(true);
        }
        setPendingAcceptOffer(null);
      })
      .catch((err) => {
        setPendingAcceptOffer(null);
        console.log(err);
      });
  };

  useEffect(() => {
    if (!offerToSubmit || isLoading) return;
    if (process.env.REACT_APP_ENABLE_FC_POPUP === 'true' && vendor === 'fundingCircle') {
      if (skipShareholderDetails) {
        handleOpenModal("ADDITIONAL");
      } else {
        handleOpenModal("SHAREHOLDER");
      }
    } else if (process.env.REACT_APP_ENABLE_FC_POPUP === 'false' && vendor === 'fundingCircle') {
      handleSubmitOffer();
    }
  }, [offerToSubmit, isLoading]);

  useEffect(() => {
    async function getData() {
      const s = await fetchLeadShareholders(leadId);
      setLeadShareholdingInfo(s.data);
      setIsLoading(false);
    }
    if (leadId) getData();
  }, []);

  useEffect(() => {
    if (isTriverModalOpen === true && vendor === 'triver' && otpPopup === false) {
      let count = 1;
      let timeout = setInterval(() => {
        count++;
        if (count === 5) {
          showOtpPopup(true);
          clearInterval(timeout);
        } else {
          setProgressPercentage(count * 20);
        }
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isTriverModalOpen])

  async function submitTriverOtp(values) {
    values.offerId = offerToSubmit.offerId;
    values.leadId = leadId;
    console.log(values);
    try {
      const res = await fetch(
        `/api/vendor/triver`,
        {
          method: "POST",
          headers : {...headers, 'Content-Type': 'application/json'},
          credentials: "include",
          body: JSON.stringify(values)
        }
      );
  
      // if (!acceptedOffers.includes(offerId)) {
      //   setAcceptedOffers([...acceptedOffers, offerId]);
      // }
  
      const otpRes = await res.json();
      if (!otpRes || !otpRes.status) {
        setOtpState("error");
      } else {
        setOtpState(otpRes.status)
        let timeout = setTimeout(() => {
          window.open(otpRes.redirectUrl, '_blank');
        }, 2000);

        return () => clearTimeout(timeout);
      }
    } catch (e){
      console.log("triver err",e.message,e.stack)
    }
  }
  return (
    <>
      <FormPopup
        isMobile={isMobile}
        visible={isTriverModalOpen}
        onClose={() => setIsTriverModalOpen(false)}
      >
        <FormPopupLayout
          onClose={() => setIsTriverModalOpen(false)}
          isMobile={isMobile}
          onCancel={() => null}
          submitEnabled={true}
          isCapitalTapModal={'isCapitalTapModal'}
          submitText={"Close"}
          hasIcon={false}
          onSubmit={() => {
            setIsTriverModalOpen(false)
          }}
          hideCancel={true}
          submitStyle={
            {
              width: "130px",
              padding: 0,
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div style={{ width: "70%" }}>
              <img src="/assets/logos/triver.png" style={{ width: "inherit" }} />
            </div>
            {otpPopup === false ?
              <Space direction="vertical">
                <p style={{ fontWeight: "bold", color: "black", fontSize: "24px", width: "500px" }}>Congratulations, {applicationData?.application?.director_name?.split(" ")?.[0]}!</p>
                <p style={{ color: "black", fontSize: "20px" }}>
                  Sending your application to Triver
                  <Progress percent={progressPercentage} />
                </p>
              </Space> :
              <Space direction="vertical">
                {otpState === "error" && <p>Incorrect OTP</p>}
                {otpState === "success" && <p>OTP Submitted Successfully. <br /><br /> Please wait while we redirect you to Triver.</p>}
                {(otpState === "" || otpState === "error") && <Form onFinish={submitTriverOtp}>
                  <Form.Item name="otp" label="Please enter OTP received on your number" labelCol={{span:24}} colon={false} rules={[{ required: true }]}>
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item><Button htmlType="submit">Send</Button>
                  </Form.Item>
                </Form>}
              </Space>
            }
          </div>
        </FormPopupLayout>
      </FormPopup>
      <FormPopup
        isMobile={isMobile}
        visible={isCapitalTapModalOpen}
        onClose={() => setIsCapitalTapModalOpen(false)}
      >
        <FormPopupLayout
          onClose={() => setIsCapitalTapModalOpen(false)}
          isMobile={isMobile}
          onCancel={() => null}
          submitEnabled={true}
          isCapitalTapModal={'isCapitalTapModal'}
          submitText={"Close"}
          hasIcon={false}
          onSubmit={() => {
            setIsCapitalTapModalOpen(false)
          }}
          hideCancel={true}
          submitStyle={
            {
              width: "130px",
              padding: 0,
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div style={{ width: "70%" }}>
              <img src="/assets/logos/capitalon tap.png" style={{ width: "inherit" }} />
            </div>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "24px", width: "500px" }}>Congratulations, {applicationData?.application?.director_name?.split(" ")?.[0]}!</p>
            <p style={{ color: "black", fontSize: "20px" }}>
              Your business has been approved for {" "}
              <span style={{ fontWeight: "bold" }}>{offerAmount}</span>
            </p>
            <p style={{ maxWidth: "550px", color: "black", fontSize: "20px" }}>
              Capital on Tap will send you an email with your offer details. Follow the instructions to access your funds.
            </p>

          </div>
        </FormPopupLayout>
      </FormPopup>
      <RedirectForm
        visible={!isLoading && redirectFormVisible}
        onClose={() => handleCloseModal("REDIRECT", true)}
        onSubmit={() => console.log('oi')}
        isMobile={isMobile}
        onBack={() => console.log('iore')}
        handleSubmitOffer={handleSubmitOffer}
        redirectState={redirectState}
      />
      <CovidForm
        visible={!isLoading && covidFormVisible}
        onClose={() => handleCloseModal("COVID", true)}
        onSubmit={() => {
          handleCloseModal("COVID");
          handleOpenModal("REDIRECT");
        }}
        isMobile={isMobile}
        leadId={leadId}
        onBack={() => {
          setAdditionalQuestionsForm(true);
          setCovidFormVisible(false);
        }}
      />
      <ShareholderDetailsForm
        isMobile={isMobile}
        visible={!isLoading && shareHolderFormVisible}
        companyId={companyId}
        leadId={leadId}
        onClose={() => handleCloseModal("SHAREHOLDER", true)}
        onSubmit={() => {
          handleCloseModal("SHAREHOLDER");
          handleOpenModal("ADDITIONAL");
        }}
      />
      <AdditionalQuestionsForm
        isMobile={isMobile}
        visible={!isLoading && additionalQuestionsForm}
        leadId={leadId}
        companyId={companyId}
        onClose={() => handleCloseModal("ADDITIONAL", true)}
        onBack={() => {
          setAdditionalQuestionsForm(false);
          setShareHolderFormVisible(true);
        }}
        showBack={!skipShareholderDetails}
        onSubmit={() => {
          if (skipCovidForm) {
            handleOpenModal("REDIRECT");
          }
          handleCloseModal("ADDITIONAL");
          handleOpenModal("COVID");
        }}
      />
      <FormDocumentsPopup
        isMobile={isMobile}
        visible={popup}
        onClose={() => setPopup(false)}
        documentsRequired={documentsRequired}
      />
    </>
  );
};
