import CTA from "./Cta";
import { Row } from "antd";
import { motion } from "framer-motion";

export default function StepForwardButton({
  enabled,
  buttonId,
  text,
  onClick,
  children,
  hasIcon,
  fontWeight,
  isMobile,
  large,
  medium,
  size,
  borderRadius,
  wrappable,
  justify,
  style,
  loading,
  descale,
  className
}) {
  const wrap = wrappable || false;

  const classNames = [
    'step-forward-button',
    className ? className : '',  
    descale ? 'descale' : ''  
  ].join(' ');


  return (
    <CTA
      className={enabled ? classNames : ""}
      ctaEnabled={enabled}
      buttonId={buttonId}
      buttonText={text}
      onClick={onClick}
      loading={loading}
      style={{
        lineHeight: "29px",
        border: "none",
        fontSize: isMobile ? "18px" : "clamp(18px, 2.1vh, 23px)",
        fontWeight: fontWeight || "400",
        padding: isMobile ? "7px 20px" : "7px 24px",
        height: size ? size : isMobile ? "48px" : "clamp(50px, 6.47vh, 70px)",
        borderRadius: borderRadius || "18px",
        ...style,
      }}
    >
      <Row wrap={wrap} style={{ alignItems: "center", fontSize: "inherit", opacity: loading ? 0 : 1 }} justify={justify}>
        {children}
        {hasIcon && (
          <img
            src="/assets/right-arrow.svg"
            style={{
              widht: "30px",
              height: "30px",
              display: "block",
              marginLeft: "15px",
              position: "relative",
              top: "1.5px",
            }}
          ></img>
        )}
      </Row>
    </CTA>
  );
}
